<template>
    <v-container fluid style="margin-bottom: 100px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title class="pb-1 pt-1">
                        Filter
                    </v-card-title>
                    <v-progress-linear
                        :active="loading"
                        :indeterminate="loading"
                        absolute
                        bottom
                        color="deep-purple accent-4"
                    ></v-progress-linear>
                    <v-container fluid>
                        <v-row>
                             <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  solo clearable v-model="mill_id" :items="mills" item-value="mill_id" item-text="mill_name" label="MILL ID" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                       <v-col cols="12" xs="12" sm="5" md="2">
                                <v-autocomplete  solo clearable v-model="project" :items="projects" item-value="project" item-text="project" label="Project" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="1">
                                <v-autocomplete  solo clearable v-model="priority" :items="prior" item-value="priority" item-text="priority" label="Priority" class="ma-0 pa-0" hide-details=true></v-autocomplete>
                            </v-col>
                             <v-col cols="12" xs="12" sm="5" md="2">
                                 <v-menu ref="modal" v-model="modal2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                    <template v-slot:activator="{ on, attr }">
                                    <v-text-field solo v-model="date_from" label="Date from" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date_from" no-title @input="modal2 = false"></v-date-picker>
                                 </v-menu>
                            </v-col>
                               <v-col cols="12" xs="12" sm="3" md="2">
                                <v-menu ref="modal_to" v-model="modal_to" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">   
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field solo v-model="date_to" label="Date to" prepend-icon="mdi-calendar" clearable readonly v-bind="attrs" v-on="on" class="ma-0 pa-0" hide-details=true></v-text-field>
                                    </template>
                                  <v-date-picker v-model="date_to" no-title @input="modal_to = false"></v-date-picker>
                                     </v-menu>
                            </v-col>
                               <v-col cols="1" >
                                    <v-btn color="success" elevation="2" large class="mt-1" @click="createNew()">
                                    <v-icon dark color="white">
                                      mdi-border-color
                                    </v-icon>
                                    Create
                                    </v-btn>
                               </v-col>
                                    <v-col cols="1">   
                                <v-btn color="error" elevation="2" large class="mt-1" @click="submit()">
                                    <v-icon dark>
                                        mdi-magnify
                                    </v-icon>
                                    Search
                                </v-btn>
                            </v-col>
                            <v-col cols="12" xs="12" sm="5" md="4" >
                                <v-radio-group
                                    v-model="invoice" row class="mt-0 pa-0" hide-details=true>
                                    <v-radio label="By Date Inv" value="byDateInv"></v-radio>
                                </v-radio-group>   
                                <v-radio-group v-model="invoice" row class="mt-3 pa-0" hide-details=true>
                                    <v-radio
                                        label="By Due Date" value="byDueDate"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
               <v-dialog v-model="dialog" max-width="1000px" transition="dialog-bottom-transition">                
                                        <v-card>
                                             <v-toolbar
                                            color="primary"
                                            dark
                                            >CREATE NEW MAINTENANCE</v-toolbar>
                                            <v-card-text>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                               v-model="mill_id2"
                                                                :items="mills"
                                                                item-value="mill_id"
                                                                item-text="mill_name"
                                                                label="MILL"
                                                                clearable
                                                                solo
                                                                @change="(event) => CreateCek(event)"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="status2"
                                                                :items="mills"
                                                                default=""
                                                                item-value="status"
                                                                item-text="status"
                                                                label="status"
                                                                :disabled="disabled_search == true"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                           
                                                            <v-text-field
                                                                :disabled="disabled_search == true"
                                                                clearable
                                                                solo
                                                                v-model="project2"
                                                                label="Project"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="priority2"
                                                                :items="mills"
                                                                item-value="priority"
                                                                item-text="priority"
                                                                label="Priority"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="5" md="4">
                                                        <v-menu ref="modal" v-model="modal" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                                            <template v-slot:activator="{ on, attr }">
                                                            <v-text-field solo v-model="date_from2" label="Request Date" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="date_from2" no-title @input="modal = false"></v-date-picker>
                                                        </v-menu>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-autocomplete
                                                                clearable
                                                                solo
                                                                v-model="atcv2"
                                                                :items="mills"
                                                                item-value="attachment_available"
                                                                item-text="attachment_available"
                                                                label="Attachment"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                            <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-text-field
                                                                solo
                                                                v-model="total2"
                                                                label="Total Attachment"
                                                            ></v-text-field>
                                                        </v-col>
                                                           <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="nik2"
                                                                :items="millso"
                                                                item-value="nik"
                                                                :item-text="item => item.nik +' - '+ item.nama"
                                                                label="Requested Nik"
                                                                clearable
                                                            ></v-autocomplete>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="primary" @click="save()">Save</v-btn>
                                                <v-btn color="blue darken-1" text @click="close">Cancel</v-btn>
                                            </v-card-actions>
                                    </v-card>
                                    </v-dialog>
                                     <v-dialog v-model="dialog2" max-width="1000px" transition="dialog-bottom-transition">                
                                        <v-card>
                                            <v-toolbar
                                            color="primary"
                                            dark
                                            >EDIT MAINTENANCE</v-toolbar>
                                            <v-card-title>{{text_dialog2}}</v-card-title>
                                            <v-card-text>
                                                <v-container fluid>
                                                    <v-row>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="mill_id3"
                                                                :items="mills"
                                                                item-value="mill_id"
                                                                item-text="mill_name"
                                                                label="MILL ID"
                                                               :disabled="disabled_search == true"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="status"
                                                                :items="mills"
                                                                default=""
                                                                item-value="status"
                                                                item-text="status"
                                                                label="Status"
                                                                :disabled="disabled_search == true"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-text-field
                                                                solo
                                                                v-model="project3"
                                                                label="Project"
                                                                :disabled="disabled_search == true"
                                                            ></v-text-field>
                                                        </v-col>
                                                         <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-text-field
                                                                solo
                                                                v-model="project4"
                                                                label="Project"
                                                            ></v-text-field>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="priorityy"
                                                                :items="mills"
                                                                item-value="priority"
                                                                item-text="priority"
                                                                label="Priority"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                            <v-col cols="12" xs="12" sm="5" md="4">
                                                        <v-menu ref="modal" v-model="modal3" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                                            <template v-slot:activator="{ on, attr }">
                                                            <v-text-field solo v-model="date_from3" label="Request Date" prepend-icon="mdi-calendar" clearable readonly class="ma-0 pa-0" hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="date_from3" no-title @input="modal3 = false"></v-date-picker>
                                                        </v-menu>
                                                        </v-col>
                                                      <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-autocomplete
                                                                clearable
                                                                solo
                                                                v-model="atcv"
                                                                :items="mills"
                                                                item-value="attachment_available"
                                                                item-text="attachment_available"
                                                                label="Attachment"
                                                            ></v-autocomplete>
                                                        </v-col>
                                                               <v-col cols="12" xs="12" sm="12" md="3">
                                                            <v-text-field
                                                                solo
                                                                v-model="total"
                                                                label="Total Attachment"
                                                            ></v-text-field>
                                                        </v-col>
                                                          <v-col cols="12" xs="12" sm="12" md="4">
                                                            <v-autocomplete
                                                                solo
                                                                v-model="nik2"
                                                                :items="millso"
                                                                item-value="nik"
                                                               :item-text="item => item.nik +' - '+ item.nama"
                                                                label="Requested Nik"
                                                            ></v-autocomplete>
                                                        </v-col>
                    <v-dialog v-model="dialog3" max-width="1000px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>CREATE MAINTENACE PROJECT</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="mill_id4"
                                            :items="createnew2"
                                            label="Mill ID"
                                            item-text="mill_id"
                                            item-value="mill_id"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="noproject"
                                            :items="createnew2"
                                            label="No Project"
                                            item-text="no_project"
                                            item-value="no_project"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalC" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from3" label="Plan Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from3" no-title @input="modalC = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalD" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_to3" label="Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_to3" no-title @input="modalD = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-text-field
                                            v-model="proyek1"
                                            label="Proyek">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-text-field
                                            v-model="lossTime"
                                            label="Time Line Proyek"
                                            type="number"
                                            min="0">
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-autocomplete
                                            v-model="nik3"
                                            :items="millso"
                                            item-value="nik"
                                            :item-text="item => item.nik +' - '+ item.nama"
                                            label="Pic Nik"
                                        ></v-autocomplete>
                                    </v-col> 
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalA" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from4" label="Actual Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from4" no-title @input="modalA = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalB" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from5" label="Actual Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from5" no-title @input="modalB = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="flag"
                                            :items="createnew2"
                                            label="Active Flag"
                                            item-text="status_flag"
                                            item-value="status_flag"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                       <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-2" @click="editItem2(item)" v-on="on">
                                                        mdi-pencil
                                                        </v-icon>
                                                    </template>
                                                    <span>Edit</span>
                                                </v-tooltip>
                                            </template>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                  <v-card-actions>
                                <v-spacer></v-spacer>
                                 <v-btn color="primary" @click="save2()">Save</v-btn>
                                 <v-btn color="blue darken-1" text @click="close">Close</v-btn>                       
                               </v-card-actions>
                           </v-card>
                        </v-dialog>
                    <v-dialog v-model="newsparepart" max-width="1000px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>CREATE NEW SPAREPART</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="mill_id2"
                                            :items="mills"
                                            item-value="mill_id"
                                            item-text="mill_name"
                                            label="MILL"
                                            clearable
                                            >
                                        </v-combobox>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="spareart"
                                            :items="spare"
                                            label="Article ID"
                                            item-text="article_id"
                                            item-value="article_id"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="qtylabel"
                                            label="Req qty"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalSPARE" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from3" label="Req Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from3" no-title @input="modalSPARE = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="6">
                                          <v-text-field
                                            clearable            
                                            v-model="notespar"
                                            label="note"
                                    ></v-text-field>
                                    </v-col>
                                       <template v-slot:[`item.actions`]="{ item }">
                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon small class="mr-2" @click="editItem(item)" v-on="on">
                                                        mdi-pencil
                                                        </v-icon>
                                                    </template>
                                                    <span>Edit</span>
                                                </v-tooltip>
                                            </template>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                  <v-card-actions>
                                <v-spacer></v-spacer>
                                 <v-btn color="primary" @click="saveNewSparepart()">Save</v-btn>
                                 <v-btn color="blue darken-1" text @click="close">Close</v-btn>                       
                               </v-card-actions>
                           </v-card>
                        </v-dialog>
                       <v-dialog v-model="dialognyanewtimline" max-width="1000px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <v-card-title>CREATE NEW TIMELINE</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="mill_id4"
                                            :items="createnew2"
                                            label="Mill ID"
                                            item-text="mill_id"
                                            item-value="mill_id"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="noproject"
                                            :items="createnew2"
                                            label="No Project"
                                            item-text="no_project"
                                            item-value="no_project"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalC" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from3" label="Plan Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from3" no-title @input="modalC = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalD" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_to3" label="Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_to3" no-title @input="modalD = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-combobox
                                            v-model="proyek1"
                                            label="Proyek"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-text-field
                                            v-model="lossTime"
                                            label="Time Line Proyek"
                                            type="number"
                                            min="0"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-autocomplete
                                            v-model="nik3"
                                            :items="millso"
                                            item-value="nik"
                                            :item-text="item => item.nik +' - '+ item.nama"
                                            label="Pic Nik"
                                        ></v-autocomplete>
                                    </v-col> 
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalA" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from4" label="Actual Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from4" no-title @input="modalA = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalB" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from5" label="Actual Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from5" no-title @input="modalB = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="flag"
                                            :items="createnew2"
                                            label="Active Flag"
                                            item-text="status_flag"
                                            item-value="status_flag"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <template v-slot:[`item.actions`]="{ item }">
                                    <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <v-icon small class="mr-2" @click="editItem2(item)" v-on="on">
                                    mdi-pencil
                                    </v-icon>
                                    </template>
                                    <span>Edit</span>
                                    </v-tooltip>
                                    </template>
                                    </v-row>
                                    </v-container>
                                    </v-card-text>
                                    <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" @click="saveCreateNew()">Save</v-btn>
                                    <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                                    </v-card-actions>
                                        </v-card>
                                        </v-dialog>
                    <v-dialog v-model="dialog5" max-width="1000px" persistent transition="dialog-bottom-transition">                
                    <v-card>
                        <!-- Dialog Edit -->
                        <v-card-title>SPAREPART</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="mill_id4"
                                            :items="createnew2"
                                            label="Mill ID"
                                            item-text="mill_id"
                                            item-value="mill_id"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="noproject"
                                            :items="createnew2"
                                            label="No Project"
                                            item-text="no_project"
                                            item-value="no_project"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalC" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from3" label="Plan Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from3" no-title @input="modalC = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalD" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_to3" label="Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_to3" no-title @input="modalD = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-combobox
                                            v-model="proyek1"
                                            label="Proyek"
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-text-field
                                            v-model="lossTime"
                                            label="Time Line Proyek"
                                            type="number"
                                            min="0"
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-autocomplete
                                            v-model="nik3"
                                            :items="millso"
                                            item-value="nik"
                                            :item-text="item => item.nik +' - '+ item.nama"
                                            label="Pic Nik"
                                        ></v-autocomplete>
                                    </v-col> 
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalA" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from4" label="Actual Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from4" no-title @input="modalA = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalB" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from5" label="Actual Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from5" no-title @input="modalB = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="flag"
                                            :items="createnew2"
                                            label="Active Flag"
                                            item-text="status_flag"
                                            item-value="status_flag"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                       <template v-slot:[`item.actions`]="{ item }">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                        small
                                                                        class="mr-2"
                                                                        @click="editItem2(item)"
                                                                        v-on="on"
                                                                       
                                                                        >
                                                                        mdi-pencil
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>
                                                            </template>
                                                            </v-row>
                                                    </v-container>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                        <v-btn color="primary" @click="save2()">Save</v-btn>
                                                        <v-btn color="blue darken-1" text @click="close">Close</v-btn>
                                                    </v-card-actions>
                                                    </v-card>
                                                    </v-dialog>
                                            <v-container fluid>
                                                <v-row>
                                                    <v-col cols="12">
                                                                <v-stepper v-model="step">
                                                                    <v-stepper-header>
                                                                        <v-stepper-step
                                                                            :complete="step > 1"
                                                                            step="1"
                                                                            @click="step = 1"
                                                                            style="cursor:pointer"
                                                                        >
                                                                            TIMELINE
                                                                        </v-stepper-step>
                                                                           
                                                                        <v-divider>
                                                                        </v-divider>
                                                                        <v-stepper-step
                                                                            :complete="step > 2"
                                                                            step="2"
                                                                            @click="step = 2"
                                                                            style="cursor:pointer"
                                                                        >
                                                                            REQUEST SPAREPART
                                                                        </v-stepper-step>
                                                                        <v-divider></v-divider>
                                                                        </v-stepper-header>
                                                                        <!-- -- tombol di atas datatable -- -->
                                                                    <v-stepper-content step="1">
			                                                        <v-col cols="12">
                                                                         <div class="col-lg-12 text-right">
                                                                            <v-btn class="mr-4" color="primary" elevation="2" small @click="createNewtimeline()">NEW TIMELINE<v-icon right dark>mdi-plus</v-icon></v-btn>
                                                                            <v-btn class="mr-4" color="success" elevation="2" small @click="TampilEdit()">REFRESH DATA<v-icon right dark>mdi-magnify</v-icon></v-btn>
                                                                            </div>  
                                                                    </v-col>
                                                            <v-data-table
                                                            :headers="headers2"
                                                            :items="adah2"
                                                            :loading="loading"
                                                            loading-text="Please wait, retrieving data"
                                                            :page.sync="pagination"
                                                            page-count="10"
                                                            item-key="key"
                                                            class="elevation-1"
                                                            >
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                        small
                                                                        class="mr-2"
                                                                        @click="editItemo(item)"
                                                                        v-on="on"    
                                                                        >
                                                                        mdi-pencil
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-data-table>
                                                        </v-stepper-content>
                                                        <v-stepper-content step="2">
			                                            <div class="col-12 ">
                                                               <div class="col-lg-12 text-right">
                                                                   <v-btn class="mr-4" color="primary" elevation="2" small @click="newspareparts()">NEW SPAREPART<v-icon right dark>mdi-plus</v-icon></v-btn>
                                                                   <v-btn class="mr-4" color="success" elevation="2" small @click="GetSparepart()">REFRESH DATA<v-icon right dark>mdi-magnify</v-icon></v-btn>
                                                               </div>
                                                        </div>
                                                            <v-data-table
                                                            :headers="headers3"
                                                            :items="dataSparepart"
                                                            :loading="loading"
                                                            loading-text="Please wait, retrieving data"
                                                            :page.sync="pagination"
                                                            page-count="10"
                                                            item-key="key"
                                                            class="elevation-1"
                                                            >
                                                            <template v-slot:[`item.actions`]="{ item }">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                        small
                                                                        class="mr-2"
                                                                        @click="editSparepart(item)"
                                                                        v-on="on"     
                                                                        >
                                                                        mdi-pencil
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>
                                                            </template>
                                                        </v-data-table>
                                                      </v-stepper-content>
                                                   </v-stepper>
                                                </v-col>
                                            </v-row>
                        <v-dialog v-model="dialog4" max-width="1000px" persistent transition="dialog-bottom-transition">                
                        <v-card>
                        <v-card-title>EDIT PROJECT ACTION</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="mill_id4"
                                            :items="createnew2"
                                            label="Mill ID"
                                            item-text="mill_id"
                                            item-value="mill_id"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="noproject"
                                            :items="createnew2"
                                            label="No Project"
                                            item-text="no_project"
                                            item-value="no_project"
                                            clearable
                                        >
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalD" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from3" label="Plan Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from3" no-title @input="modalD = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalE" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_to3" label="Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_to3" no-title @input="modalE = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                    <v-text-field
                                            clearable            
                                            v-model="proyek1"
                                            label="Proyek"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-text-field
                                            v-model="lossTime"
                                            label="Time Line Proyek"
                                            type="number"
                                            min="0"
                                            >
                                        </v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="4">
                                        <v-autocomplete
                                            v-model="nik3"
                                            :items="millso"
                                            item-value="nik"
                                            :item-text="item => item.nik +' - '+ item.nama"
                                            label="Pic Nik"
                                        ></v-autocomplete>
                                    </v-col> 
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalG" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from4" label="Actual Start Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from4" no-title @input="modalG = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalF" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from5" label="Actual Finish Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from5" no-title @input="modalF = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                       <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-text-field
                                            v-model="flag"
                                            label="Active Flag"
                                            clearable
                                        >
                                        </v-text-field>
                                    </v-col>
                                       <template v-slot:[`item.actions`]="{ item }">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                        small
                                                                        class="mr-2"
                                                                        @click="editItem2(item)"
                                                                        v-on="on"
                                                                       
                                                                        >
                                                                        mdi-pencil
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>
                                                            </template>
                                                    </v-row>
                                                        </v-container>
                                                            </v-card-text>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <!-- penting -->
                                                        <v-btn color="primary" @click="UpdateEditProjectMaintenance()">Save</v-btn>
                                                         <v-btn color="blue darken-1" text @click="cencell2()">Close</v-btn>
                                                            </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>
                <v-dialog v-model="dialogSparepart" max-width="1000px" persistent transition="dialog-bottom-transition">                
                        <v-card>
                        <v-card-title>EDIT SPAREPART</v-card-title>
                        <v-card-text>
                            <v-container fluid>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="mill_id4"
                                            :items="createnew2"
                                            label="Mill Name"
                                            item-text="mill_id"
                                            item-value="mill_id"
                                            :disabled="disabled_search == true"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="3">
                                       <v-text-field
                                        v-model="project3"
                                        label="Project"
                                        :disabled="disabled_search == true"                        
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="namaprodukspar"
                                            :items="dataSparepart"
                                            label="Name Product"
                                            item-text="name_product"
                                            item-value="name_product"
                                            :disabled="disabled_search == true"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="wrspar"
                                            :items="dataSparepart"
                                            label="WR Action No"
                                            item-text="wr_action_no"
                                            item-value="wr_action_no"
                                            :disabled="disabled_search == true"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="itemspar"
                                            :items="dataSparepart"
                                            label="Item No"
                                            item-text="item_no"
                                            item-value="item_no"
                                            :disabled="disabled_search == true"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                      <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-combobox
                                            v-model="typesapr"
                                            :items="dataSparepart"
                                            label="Req Type"
                                            item-text="req_type"
                                            item-value="req_type"
                                            :disabled="disabled_search == true"
                                            clearable>
                                        </v-combobox>
                                    </v-col>
                                      <v-col cols="12" xs="12" sm="12" md="3">
                                        
                                    <v-text-field
                                        v-model="qtyspar"
                                        label="Req QTY"                        
                                    ></v-text-field>
                                      
                                    </v-col>
                                        <v-col cols="12" xs="12" sm="12" md="3">
                                        <v-menu ref="modal" v-model="modalG" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
                                        <template v-slot:activator="{ on, attr }">
                                        <v-text-field v-model="date_from3" label="Req Date"  clearable readonly hide-details=true v-bind="attr" v-on="on"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="date_from3" no-title @input="modalG = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                     <v-col cols="12" xs="12" sm="12" md="5">
                                          <v-text-field
                                            clearable            
                                            v-model="editspar"
                                            label="note"
                                    ></v-text-field>
                                    </v-col>
                                       <!-- <template v-slot:[`item.actions`]="{ item }">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-icon
                                                                        small
                                                                        class="mr-2"
                                                                        @click="editItemSpar(item)"
                                                                        v-on="on">
                                                                        mdi-pencil
                                                                        </v-icon>
                                                                    </template>
                                                                    <span>Edit</span>
                                                                </v-tooltip>
                                                            </template> -->
                                                    </v-row>
                                                        </v-container>
                                                            </v-card-text>
                                                            <v-card-actions>
                                                                <v-spacer></v-spacer>
                                                                <!-- penting -->
                                                        <v-btn color="primary" @click="UpdateSparepart()">Save</v-btn>
                                                         <v-btn color="blue darken-1" text @click="cencell2()">Close</v-btn>
                                                            </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>
                                                </v-container>
                                                </v-row>
                                                </v-container>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <!-- gak penting -->
                                                <!-- <v-btn color="primary" @click="SaveEdit()">Save</v-btn> -->
                                                <v-btn color="blue darken-1" text @click="cencell()">Cancel</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                <v-col cols="12" style="padding-bottom: 100px">
                                <v-skeleton-loader v-if="loading_loader" :loading="loading_loader" type="card, table"></v-skeleton-loader>
                                <div v-show="loading_loader === false">
                                <v-container fluid>
                                <v-row>
                                <v-card-title>Result
                                </v-card-title>
                                <v-col cols="12" xs="12" sm="12" md="12">
                                <v-card
                                    class="mx-auto"
                                    color="blue lighten-5"
                                    >
                                    <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-title style="text font-weight-black" class="text-h5sd">
                                        MAINTENANCE
                                        </v-list-item-title>
                                        <v-list-item-subtitle>Report</v-list-item-subtitle>
                                    </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>                              
                            <v-col cols="12" xs="12" sm="12" md="12">
                                <v-data-table
                                    :headers="headers"
                                    :items="raw_materials"
                                    :options.sync="optionss"
                                    :loading="loading"
                                    loading-text="Please wait, retrieving data"
                                    :server-items-length="totalItemss"
                                    :page.sync="pagination"
                                    page-count="10"
                                    class="elevation-1"
                                >
                                    <template v-slot:[`item.actions`]="{ item }" >
                                    <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                    <v-icon
                                    color="primary"
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                                    v-on="on" 
                                    >
                                    mdi-pencil
                                    </v-icon>
                                    </template>
                                    <span>Edit</span>
                                    </v-tooltip>
                                    </template>
                                </v-data-table>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </v-col>
        </v-row>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
            <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
            </v-layout>
            <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
            </v-btn>
        </v-snackbar>
        </v-container>
</template>
<script>


export default {
    data() {
        return {
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Kepuh Kencana Arum',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Proyek Maintenance',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Proyek Maintenance',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            loading:false,
            offices: [{
                office_id : 'KKA',
                office : 'KKA'
            }],
            office: 'SR',
            projects: [],
            prior: [],
            disabled_search: true,
            priority:'',
            text_dialog:'',
            text_dialog2: '',
            text_dialog3:'',
            project3:'',
            date_from2:'',
            date_from3:'',
            date_from4:'',
            date_from5:'',
            modalD:'',
            modalE:'',
            modalG:'',
            modalF:'',
            project:'',
            option_items:'',
            
            items:[],
            mill_id:'',
            lossTime: '',
            proyek1:'',
            qtylabel:'',
            notespar:'',
            editspar:'',
            flag:'',
            modalA:'',
            mill_id2:'',
            mill_id4:'',
            request_date2:'',
            atcv2:'',
            nik2:'',
            nik3:'',
            total2:'',
            status_prev:'',
            priority2:'',
            project2:'',
            noproject:'',
            namaprodukspar:'',
            wrspar:'',
            itemspar:'',
            qtyspar:'',
            typesapr:'',
            spareart:'',
            dateProps: {
            headerColor: 'blue',
            },
            status2:'',
            mill_id3:'',
            description:'',
            mills: [],
            spare: [],
            millso:[],
            createnew2:[],
            customer: '',
            modal2:'',
            priorityy:'',
            atcv:'',
            modal3:'',
            modalC:'',
            modalD:'',
            due_date:'',
            total:'',
            dataSparepart:[],
             headers3: [
                   { text: "Actions", value: "actions", sortable: false },
                {
                    text: "No Wr",
                    align: "start",
                    value: "no_wr",
                },
                { text: "Article", value: "article_id" },
                { text: "Mach Type", value: "mach_type" },
                { text: "Name Product", value: "name_product" },
                { text: "Note", value: "note" },
                { text: "Req qty", value: "req_qty" },
                
                
            ],
            status:'',
            adah2:[],
              headers2: [
                   { text: "Actions", value: "actions", sortable: false },
                {
                    text: "Project Action No",
                    align: "start",
                    value: "project_action_no",
                },
                { text: "Timeline Proyek", value: "timeline_proyek" },
                { text: "Remark", value: "remark" },
                { text: "Pic Nik", value: "pic_nik" },
                { text: "Nama", value: "nama" },
                { text: "Plan Start Date", value: "plan_start_date" },
                { text: "Plan Finish Date", value: "plan_finish_date" },
                { text: "Actual Start Date", value: "actual_start_date" },
                { text: "Actual Finish Date", value: "actual_finish_date" },
                { text: "Status flag", value: "status_flag" },
                
            ],
            dialog: false,
            dialog2: false,
            dialog3: false,
            dialognyanewtimline: false,
            newsparepart: false,
            dialog4: false,
            dialogSparepart: false,
            dialog5: false,
            display: 'none',
            option_po_items: {},
            loading3: false,
            loading4: false,
            loading5: false,
            loading_loader: false,
            sortBy: 'asc',
            sortName: '',
            raw_materials: [],
            search: null,
            headers: [
                { text: "Actions", value: "actions", sortable: false },   
               {
                    text: 'Mill ID',
                    align: 'start',
                    sortable: false,
                    value: 'mill_id',
                },
                { text: 'No Project', value: 'no_project', sortable: false },
                { text: 'Status', value: 'status', sortable: false },
                { text: 'Project', value: 'project', sortable: false },
                { text: 'Priority', value: 'priority', sortable: false },
                { text: 'Due Date', value: 'due_date', sortable: false },
                { text: 'Total Attachment',  value: 'total_attachment', sortable: false },
                { text: 'Requested Nik',  value: 'requested_nik', sortable: false },
                { text: 'Requested Date',  value: 'request_date', sortable: false },
                { text: 'Approved 1 By',  value: 'approved_1_nik', sortable: false },
                { text: 'Approved 1 Date',  value: 'approved_1_date', sortable: false },
                { text: 'Approved 2 By',  value: 'approved_2_nik', sortable: false },
                { text: 'Approved 2 Date',  value: 'approved_2_date', sortable: false },
                                       
            ],
            totalItems: 10,
            totalItemss:10,
            pagination: 1,
            options: {},
            optionss:{},
            date_from: '',
            modalB:'',
            step:'',
            date_to3:'',
            project4:'',
            date_from2:'',
            modal2:false,
            modal3:false,
            modalC:false,
            modalSPARE:false,
            modalD:false,
            modalE:false,
            modalG:false,
            modalF:false,
            modal: false,
            date_to: '',
            modal_to: false,
            invoice: 'byDateInv'
        }
    },
    mounted(){
        this.updateDepartment()
        this.getMill()
        this.getArticle()
        this.getPeriority()
        this.updateNik()
    },
    methods:{
        getEnvConf(){
            let data = this.data_var;

            this.$store.dispatch("getVariabelUser", { data }).then(res => {

                for (let i = 0; i < res.data.data.length; i++) {

                    if (res.data.data[i]['var_id'] === 'APRVPR1') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve1 = res.data.data[i]['var_value']
                        }
                    }
                    
                    if (res.data.data[i]['var_id'] === 'APRVPR2') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.user_approve2 = res.data.data[i]['var_value']
                        }
                    }

                    if (res.data.data[i]['var_id'] === 'RAW') {
                        if (res.data.data[i]['var_value'] != null) {
                            this.raw = res.data.data[i]['var_value']
                            this.disabled_search = true
                        }
                    }

                }

            })
        },
         close(){
            this.dialog = false
            //this.dialog2 = false
            this.dialog3 = false
            this.dialog5 = false
            this.dialognyanewtimline = false
            this.newsparepart = false
            
            
        },
         cencell(){
          
            this.dialog2 = false   
        },
        //  cencell2(){
          
        //     this.dialog4 = false   
        // },
         cencell2(){
            this.dialog4 = false
            this.dialogSparepart = false   
        },
          clear(){
            this.mill_id2 = ''
            this.dialog = ''
            //this.dialog2 = ''
            this.dialog3 = ''
            this.status = ''

        },
        
        async createNew(){
            this.dialog = true
            this.mill_id2 = ''  
        },
         async createNew2(){
            this.dialog3 = true
            
        },
          async createNew3(){
            this.dialog5 = true
            
        },
         async createNewtimeline(){
            this.dialognyanewtimline = true
            
        },
         async newspareparts(){
            this.newsparepart = true
            
        },
          async CreateCek(value){
            
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek?mill_id=${value}`,{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
               // console.log(res.data.data[0].status)
                this.status2 = res.data.data[0] ? res.data.data[0].status : ''
                this.project2 = res.data.data[0] ? res.data.data[0].project : ''
			})
			.catch(err => {
			})
        },
        
        async getMill(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
            
                this.mills = res.data.data
                
			})
			.catch(err => {
			})
        },

         async getArticle(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/kka/sparepart/sparepart`,
			{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                //console.log(res.data)
                this.spare = res.data
                
			})
			.catch(err => {
			})
        },

        async getPeriority(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek`,{
				headers: {Authorization: `Bearer ${this.$store.getters.isTokenUser}`,},
          	})
			.then(res => {
                this.prior = res.data.data
			})
			.catch(err => {
			})
        },
          updateNik(){
            axios.get(`${process.env.VUE_APP_URL}/api/master/kka/master_employee`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                //console.log(res.data)
                this.millso = res.data
                this.loading2 = false
            });
        },
        updateDepartment (){
            axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek?project=${this.project ? this.project : ''}`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.projects = res.data.data
                this.loading2 = false
            });
        },
        sortByFunc(sortBy){
            this.sortBy = this.sortBy === 'desc' ? 'asc' : 'desc';
            this.sortName = sortBy
            this.submit(1, 10)
        },
        async save(){
            if(this.mill_id2 == '' && this.project2 == '' && this.priority2 == '' && this.atcv2 == '' && this.total2 == '' && this.nik2 == ''){
                Swal.fire({
                            title: 'Error!',
                            text: 'Please complete all required fields !',
                            icon: 'error',
                         })
                return false
            }
            await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek`, {   
                'mill_id': this.mill_id2,            
                'status' : this.status2,
                'project' : this.project2,
                'priority' : this.priority2,
                'due_date' : this.date_from2,
                'attachment_available' : this.atcv2,
                'total_attachment' : this.total2,
                'requested_nik' : this.nik2
            }, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                
                        this.dialog_save = false
                        this.dialog = false
                        this.loading = true
                        this.submit()
                        this.clear()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Create New Maintenance",
                            visible: true
                        };
                    }).catch(err => {
                        this.loading4 = false
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                    };
            })
        },
        async editItem(cek){
            this.display = 'block'
            this.disabled_search = true
            this.dialog4 = true
            this.mill_id3 = cek.mill_id
            this.atcv = cek.attachment_available
            this.priorityy = cek.priority
            this.status = cek.status
            this.project3 = cek.no_project
            this.project4 = cek.project  
            this.status_prev = cek.status
            this.dialog_save = false
        },
        async SaveEdit(){
            if (this.status_prev === '2'){
             await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek`, { 
                        'mill_id': this.mill_id3,
                        'status': this.status,
                        'project': this.project4,
                        'priority': this.priorityy,
                        'due_date': this.date_from3,
                        'attachment_available': this.atcv,
                        'totalAttachment': this.total,
                        'requested_nik': this.nik2,
                        'status_prev': this.status     
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                        this.dialog_save = false
                        this.dialog4 = false
                        this.loading = true
                        this.submit()
                        this.clear()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Edit Maintenance",
                            visible: true
                        };
            }).catch(err => {
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        visible: true
                    };
            
            })                      
        
        }
    
    },
    async submit(page = 1, itemsPerPage = 10){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek?mill_id=${this.mill_id ? this.mill_id :''}&project=${this.project ? this.project :''}&priority=${this.priority ? this.priority :''}&dt_start=${this.date_from ? this.date_from :''}&dt_end=${this.date_to ? this.date_to:''}&due_date=${this.invoice ? this.invoice :''}&page=${page > 1 ? page : 1}&pagination=${itemsPerPage}`,  { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                console.log(res.data)
                this.totalItemss = res.data.total
                this.raw_materials = res.data.data
                this.pagination = res.data.current_page
            })
        },
    async saveCreateNew(){
            if(this.mill_id4 == '' && this.noproject == '' && this.priority2 == '' && this.atcv2 == '' && this.total2 == '' && this.nik3== ''){
                Swal.fire({
                title: 'Error!',
                text: 'Please complete all required fields !',
                icon: 'error',         
                })
                return false
            }
            await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek/detail`, {   
                'mill_id'        : this.mill_id4,
                'no_project'     : this.noproject,
                'remark'         : this.proyek1,
                'pic_nik'        : this.nik3,
                'plan_start_date'   : this.date_from3,
                'plan_finish_date'  : this.date_to3,
                'actual_start_date' : this.date_from4,
                'timeline_proyek'   : this.lossTime,
                'actual_finish_date': this.date_from5,
                'status_flag'       : this.flag
            }, { 
            headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                //console.log(res.data)
                        this.dialog_save = false
                        this.dialognyanewtimline = false
                        this.dialog4 = false
                        this.loading = true
                        this.TampilEdit()
                        this.clear()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Create New Maintenance",
                            visible: true
                        };
                    }).catch(err => {
                        this.loading4 = false
                        this.snackbar = {
                            color: "error",
                            icon: "mdi-alert-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Error",
                            text: err.response.data.message,
                            visible: true
                        };
                    })
        },
          async editItem(cek){
            //console.log(cek.no_project)
            this.display = 'block'
            this.status = cek.status
            this.mill_id3 = cek.mill_id
            this.disabled_search = true
            this.dialog2 = true
            this.project3 = cek.no_project
            this.mach_type = "PRJ"
            this.mach_id = "00"
            this.wr_action_no = "1"
            this.req_source = "2"
        },
          async saveNewSparepart(){
            if (this.mach_type === "PRJ" && this.mach_id === "00" && this.wr_action_no === "1" && this.req_source === "2"){
                await axios.post(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart/store`, {   
                    'mill_id': this.mill_id2.mill_id,
                    'no_wr': this.project3,
                    'article_id': this.spareart.article_id,
                    'req_qty': this.qtylabel,
                    'note': this.notespar,
                    'req_date': this.date_from3,
                    'mach_type': this.mach_type,
                    'mach_id': this.mach_id,
                    'wr_action_no': this.wr_action_no,
                    'req_source': this.req_source  
                }, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                }).then(res => {
                            this.GetSparepart()
                            this.newsparepart = false
                            this.loading = true
                            this.snackbar = {
                                color: "success",
                                icon: "mdi-checkbox-marked-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Success",
                                text: "Successfully Create New Maintenance",
                                visible: true
                            };
                        }).catch(err => {
                            this.loading4 = false
                            this.snackbar = {
                                color: "error",
                                icon: "mdi-alert-circle",
                                mode: "multi-line",
                                position: "top",
                                timeout: 7500,
                                title: "Error",
                                text: err.response.data.message,
                                visible: true
                    };
              })
            }
        },
       async editSparepart(cek){
             console.log(cek)
             this.wr_action_no = "1"
             this.itemspar = cek.item_no
             this.req_type = "G"
             this.dialogSparepart = true
             this.mill_id4 = cek.mill_id
             this.namaprodukspar = cek.name_product
        },
        async UpdateSparepart(){
            if (this.req_type === "G" && this.wr_action_no === "1"){
             await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart/update`, { 
                        // 'mill_id': this.mill_id3,
                        'no_wr': this.project3,
                        'wr_action_no': this.wr_action_no,
                        'item_no': this.itemspar,
                        'req_type': this.req_type,
                        'req_date': this.date_from3,
                        'req_qty': this.qtyspar,
                        'note': this.editspar,    
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                        this.dialog_save = false
                        this.dialogSparepart = false
                        this.loading = true
                         this.GetSparepart()
                        // this.submit()
                        // this.clear()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Edit Maintenance",
                            visible: true
                        };
            }).catch(err => {
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        visible: true
                    };
            
            })                      
        
        }
    
    },
        async TampilEdit(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek/detail?mill_id=${this.mill_id ? this.mill_id :''}&project=${this.project ? this.project :''}&priority=${this.priority ? this.priority :''}&dt_start=${this.date_from ? this.date_from :''}&dt_end=${this.date_to ? this.date_to:''}&due_date=${this.invoice ? this.invoice :''}`,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
                //console.log(res.data)
                this.adah2 = res.data.data
            })
        },
        async editItemo(cek){
             this.dialog4 = true
             this.mill_id4 = cek.mill_id
             this.noproject = cek.no_project
             this.flag = cek.status_flag
             this.status_prev = cek.status
             this.proyek1 = cek.remark
        },
       
         async GetSparepart(){
            await axios.get(`${process.env.VUE_APP_URL}/api/kka/maintenance/maintenance_sparepart?mill_id=${this.mill_id ? this.mill_id :''}&project=${this.project ? this.project :''}&priority=${this.priority ? this.priority :''}&dt_start=${this.date_from ? this.date_from :''}&dt_end=${this.date_to ? this.date_to:''}&due_date=${this.invoice ? this.invoice :''}`,  { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.loading = false
               // console.log(res.data)
                this.dataSparepart = res.data.data
            })
        },
        async editItemo(cek){
            console.log(cek)
             this.dialog4 = true
             this.mill_id4 = cek.mill_id
             this.noproject = cek.no_project
             this.flag = cek.status_flag
             this.status_prev = cek.status
             this.proyek1 = cek.remark
             this.status_prev = "2"
        },
        async UpdateEditProjectMaintenance(){
           if(this.status_prev === "2"){
             await axios.put(`${process.env.VUE_APP_URL}/api/kka/maintenance/proyek/detail`, { 
                        'mill_id': this.mill_id4,
                        'no_project': this.noproject,
                        'timeline_proyek': this.lossTime,
                        'remark' : this.proyek1,
                        'pic_nik': this.nik3,
                        'plan_start_date': this.date_from3,
                        'plan_finish_date': this.date_to3,
                        'actual_finish_date': this.date_from5,
                        'status_flag': this.flag,
                        'status_prev': this.status_prev             
                    }, { 
                        headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            }).then(res => {
                         this.dialog_save = false
                         this.dialog4 = false
                         this.loading = true
                         this.submit()
                         this.clear()
                        this.snackbar = {
                            color: "success",
                            icon: "mdi-checkbox-marked-circle",
                            mode: "multi-line",
                            position: "top",
                            timeout: 7500,
                            title: "Success",
                            text: "Successfully Edit Maintenance",
                            visible: true
                        };
            }).catch(err => {
                    this.dialog_save = false
                    this.snackbar = {
                        color: "error",
                        icon: "mdi-alert-circle",
                        mode: "multi-line",
                        position: "top",
                        timeout: 7500,
                        title: "Error",
                        visible: true
                    };
            })                      
        
           }
    
    },
            
    },
    watch: {
        options: {
        handler () {
                const { page, itemsPerPage } = this.options
                if (this.display != 'none') {
                    this.submit(page, itemsPerPage)
                }
            },
            deep: true
        },
        optionss: {
            handler () {
                const { page, itemsPerPage } = this.optionss
                    this.submit(page, itemsPerPage)
                
            },
            deep: true,
        },
        search (val) {
            val && val !== this.customer && this.querySelections(val)
        },
    }
}
</script>
<style scoped>
.theme--light.v-data-table {
    background-color: #fffefe;
    color: rgba(0, 0, 0, 0.87);
}
</style>
